import React from "react";
import "./styles/resume-main.css";
import "./styles/resume-sections.css";
import Top from "./components/Top";
import Coursework from "./components/Coursework";
import Certifications from "./components/Certifications";
import WorkExperience from "./components/WorkExperience";
import Skills from "./components/Skills";

function Resume() {
  return (
    <main className="resume">
      <Top />
      <Skills type="tech" title="Technical Skills" class="technical-skills" />
      <Coursework />
      <Certifications />
      <WorkExperience />
      <Skills type="general" title="Skills" class="general-skills" />
    </main>
  );
}

export default Resume;
