import React from "react";
import { techSkills, generalSkills } from "../../content/ResumeInfo";

function Skills(props) {
  let skills;
  props.type === "tech" ? (skills = techSkills) : (skills = generalSkills);

  const skillLine = skills.map((item) => {
    return (
      <p>
        <span>{item.Type}:&nbsp;</span> {item.Skill.join(", ")}
      </p>
    );
  });

  return (
    <section className={props.class}>
      <h2 className="line">{props.title}</h2>
      {skillLine}
    </section>
  );
}
export default Skills;
