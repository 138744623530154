import React from "react";
import Icon from "../../Icon";

function Top() {
  return (
    <div className="top-resume">
      <Icon />
      <div className="title">
        <div className="split line">
          <h1>Luiza Benvenuti Hernandez</h1>
          <a href="https://drive.google.com/file/d/1IrcqeuBfJbf5XNY2dyRFXjvL1lyrbTt-/view?usp=drive_link" target="_blank" rel="noreferrer" className="button-resume">
            PDF
          </a>
        </div>
        <div className="split education">
          <h2>
            B.S Software Engineering, <i>Western Governors University</i>
          </h2>
          <h2>
            Dec 2025 <i>(anticipated)</i>
          </h2>
        </div>
        <div className="split">
          <h2>
            B.A. Psychology, <i>University of Nebraska-Lincoln</i>
          </h2>
          <h2>Dec 2021</h2>
        </div>
      </div>
    </div>
  );
}

export default Top;
