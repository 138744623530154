import React from "react";
import "./header-style.css";

function Header(props) {
  const onPage = { color: "var(--highlight)" };

  return (
    <header>
      <ul>
        <li
          onClick={props.handleClick}
          style={props.pageName === "Home" ? onPage : null}
        >
          Home
        </li>
        <li
          onClick={props.handleClick}
          style={props.pageName === "Projects" ? onPage : null}
        >
          Projects
        </li>
        <li
          onClick={props.handleClick}
          style={props.pageName === "Resume" ? onPage : null}
        >
          Resume
        </li>
        <li
          onClick={props.handleClick}
          style={props.pageName === "Contact Me" ? onPage : null}
        >
          Contact Me
        </li>
      </ul>
    </header>
  );
}

export default Header;
