import React from "react";
import "../styles/profile-page.css";
import "../styles/profile-imgs-container.css";
import { SingleImg, MultipleImgs } from "./ImgSelection";

function ProjectProfile(props) {
  const langList = props.languages.map((item) => {
    return <li>{item}</li>;
  });

  return (
    <section className="profile">
      <div className="profile-left">
        {props.URL ? (
          <div>
            <h2>{props.title}</h2>
            <a href={props.URL} target="_blank" rel="noreferrer">
              Open
            </a>
          </div>
        ) : (
          <h2>{props.title}</h2>
        )}

        <SingleImg imgCode={props.code} />
        <ul className="box-shadow">{langList}</ul>
      </div>
      <div className="profile-right">
        <p>
          <span>Objective:&nbsp;</span> {props.objective}
        </p>
        <p className="description">{props.description}</p>
      </div>
      <MultipleImgs imgCode={props.code} />
    </section>
  );
}

export default ProjectProfile;
