import React from "react";
import { workExperience } from "../../content/ResumeInfo";

function WorkItems(props) {
  let bulletPoints = props.description.map((bullet) => {
    return <li>{bullet}</li>;
  });

  return (
    <div className="work-experience">
      <div>
        <h3>
          {props.employer}, {props.location}
        </h3>
        <p>{props.workingPeriod}</p>
      </div>
      <p>
        <i>{props.position}</i>
      </p>
      <ul>{bulletPoints}</ul>
    </div>
  );
}

function WorkExperience() {
  let experiences = workExperience.map((item) => {
    return (
      <WorkItems
        key={item.ID}
        employer={item.Employer}
        location={item.Location}
        workingPeriod={item.Period}
        position={item.Position}
        description={item.Description}
      />
    );
  });

  return (
    <section>
      <h2 className="line">Relevant Work Experience</h2>
      {experiences}
    </section>
  );
}

export default WorkExperience;
