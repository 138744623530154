import React from "react";
import classes from "../../content/ClassInfo.js";
import ProgressBar from "./ProgressBar.jsx";
import ClassCard from "../../ClassCard.jsx";
import "../styles/education.css";

function Education() {
  return (
    <div className="education-container">
      <h3 className="title">
        Pursuing <span>B.S. in Software Engineering</span> at Western Governors
        University
      </h3>
      <p>Accelerated Program - December 2025 (anticipated)</p>
      <ProgressBar progress={classes.length - 1} />
      <p className="subtitle">Currently enrolled in:</p>
      <ClassCard
        key={classes.ID}
        typeCard="education-card box-shadow"
        title={classes[0].Name}
      />
    </div>
  );
}

export default Education;
