import React from "react";

function ClassCard(props) {
  return (
    <div className={props.typeCard}>
      <h3>{props.title}</h3>
      <p>
        {props.curEnrrol && "Currently Enrrolled - "}
        {props.compUnits != null && `Competency Units: ${props.compUnits}`}
      </p>
      <p>{props.description}</p>
    </div>
  );
}

export default ClassCard;
