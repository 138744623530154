import React from "react";
import { certifications } from "../../content/ResumeInfo";

function CertItem(props) {
  return (
    <div className="split">
      <h3>
        {props.name}, <i>{props.from}</i> – {props.when}
      </h3>
      <a href={props.url} target="_blank" rel="noreferrer" className="button-resume">
        PDF
      </a>
    </div>
  );
}

function Certifications() {
  let certElements = certifications.map(function (item) {
    return (
      <CertItem
        key={item.ID}
        name={item.Name}
        from={item.Location}
        url={item.Link}
        when={item.Date}
      />
    );
  });

  return (
    <section>
      <h2 className="line">Certifications</h2>
      <div>{certElements}</div>
    </section>
  );
}

export default Certifications;
