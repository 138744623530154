import React from "react";
import ProjectItem from "./components/ProjectItem";
import ProjectProfile from "./components/ProjectProfile";
import "./styles/projects-main.css";
import { featuredProjects, portfolio } from "../content/Projects";

function Projects() {
  const [profile, setProfile] = React.useState("");
  const project = featuredProjects.find(
    ({ projectCode }) => projectCode === profile
  );

  function handleProfile(e) {
    setProfile(e.target.id);
  }

  function handleBack() {
    setProfile("");
  }

  function renderProfile() {
    window.scrollTo(0, 0);
    return (
      <ProjectProfile
        key={project.ID}
        title={project.Title}
        code={project.projectCode}
        objective={project.Objective}
        description={project.Description}
        languages={project.Languages}
        URL={project.URL}
      />
    );
  }

  const features = featuredProjects.map((item) => {
    return (
      <ProjectItem
        key={item.ID}
        code={item.projectCode}
        altText={item.Alt}
        title={item.Title}
        objective={item.Objective}
        languages={item.Languages}
        handleProfile={handleProfile}
      />
    );
  });

  return (
    <main className="projects">
      {profile === "" ? (
        <h1>Projects</h1>
      ) : (
        <div className="back-top">
          <button className="box-shadow" onClick={handleBack}>
            Back
          </button>
          <h1>Projects</h1>
        </div>
      )}

      <section className="projects-container">
        {/* PORTFOLIO - PROJECT INFORMATION */}
        {profile === "" && (
          <ProjectItem
            key={portfolio.ID}
            code={portfolio.projectCode}
            noButton={portfolio.noButton}
            altText={portfolio.Alt}
            title={portfolio.Title}
            description={portfolio.Description}
            languages={portfolio.Languages}
          />
        )}
        {/* ITEMS ON FEATURED PROJECTS ARRAY */}
        {profile === "" ? features : renderProfile()}
      </section>
    </main>
  );
}

export default Projects;
