import React from "react";
import { SingleImg } from "./ImgSelection";
import "../styles/project-cards.css";

function ProjectItem(props) {
  const langList = props.languages.map((item) => {
    return <li>{item}</li>;
  });

  return (
    <div className="project-card">
      <div className="project-top">
        <h2>{props.title}</h2>{" "}
        {!props.noButton && (
          <button
            id={props.code}
            className="box-shadow"
            onClick={props.handleProfile}
          >
            Read More
          </button>
        )}
      </div>
      <SingleImg imgCode={props.code} alt={props.altText} />
      <div className="lang-used">
        <ul className="box-shadow">{langList}</ul>
      </div>
      {props.objective ? (
        <p>
          <span>Objective:&nbsp;</span> {props.objective}
        </p>
      ) : (
        <p>{props.description}</p>
      )}
    </div>
  );
}

export default ProjectItem;
