const classes = [
  {
    ID: 12,
    Name: "User Interface Design",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: true,
  },
  {
    ID: 11,
    Name: "Front-End Web Development",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 10,
    Name: "Advanced Data Management",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 9,
    Name: "Hardware and Operating Systems Essentials",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 8,
    Name: "Version Control ",
    CompUnits: 1,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 7,
    Name: "Data Structures and Algorithms I ",
    CompUnits: 4,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 6,
    Name: "Scripting and Programming - Foundations",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 5,
    Name: "Data Management - Applications",
    CompUnits: 4,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 4,
    Name: "Data Management - Foundations",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 3,
    Name: "Network and Security - Foundations",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 2,
    Name: "Web Development Foundations",
    CompUnits: 3,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
  {
    ID: 1,
    Name: "Introduction to IT",
    CompUnits: 4,
    Description: "I need to add a short description here",
    CurrentlyEnrrolled: false,
  },
];

export default classes;
