import React from "react";
import "./footer-style.css";
import LinkedInIcon from "./linkedin-icon.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p>© {currentYear} Luiza Benvenuti Hernandez.</p>
      <a href="https://www.linkedin.com/in/luizahernandez/" target="_blank" rel="noreferrer">
        <img src={LinkedInIcon} alt="LinkedIn Icon" />
      </a>
    </footer>
  );
}

export default Footer;
