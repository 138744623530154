import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Main/Home/Home.jsx";
import Projects from "./components/Main/Projects/Projects.jsx";
import Resume from "./components/Main/Resume/Resume.jsx";
import ContactMe from "./components/Main/ContactMe/ContactMe.jsx";

function App() {
  const [page, setPage] = React.useState("Home");

  function pageUpdate(e) {
    setPage(e.target.innerText);
  }

  function renderPage(page) {
    switch (page) {
      case "Home":
        return <Home />;
      case "Projects":
        return <Projects />;
      case "Resume":
        return <Resume />;
      case "Contact Me":
        return <ContactMe />;
      default:
        return <Home />;
    }
  }

  return (
    <div id="app">
      <Header handleClick={pageUpdate} pageName={page} />
      {/* MAIN */}
      {renderPage(page)}
      <Footer />
    </div>
  );
}

export default App;

