import React from "react";
import "./contact-style.css";

function ContactMe() {
  return (
    <main className="contact-me">
      <section>
        <div className="contact-container">
          <h1>I would love to hear from you.</h1>
          <h2>Feel free to...</h2>
          <div className="find-me">
            <p>
              Find me on{" "}
              <a
                href="https://www.linkedin.com/in/luizahernandez/"
                className="link-hover"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </p>
            <p>
              Or email me at{" "}
              <a
                href="mailto:lbenvenutihernandez@gmail.com"
                className="link-hover"
                target="_blank"
                rel="noreferrer"
              >
                lbenvenutihernandez@gmail.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ContactMe;
