const certifications = [
  {
    ID: 1,
    Name: "The Complete 2024 Web Development Bootcamp",
    Location: "Udemy",
    Link: "https://drive.google.com/file/d/1T3dElmmNf-LEn_UFmWTBaPX197ieV1HH/view?pli=1",
    Date: "Apr 2024",
  },
];

const techSkills = [
  {
    ID: 1,
    Type: "Proficient in",
    Skill: ["JavaScript", "React", "SQL", "HTML", "CSS", "EJS", "REST API", "JSON"],
  },
  {
    ID: 2,
    Type: "Familiar with ",
    Skill: ["Python", "Node.js", "Express.js", "NPM", "Bootstrap", "jQuery"],
  },
  { ID: 3, Type: "Basic knowledge of ", Skill: ["C#"] },
  {
    ID: 4,
    Type: "Other",
    Skill: ["Git", "Command Line", "Virtual Machine", "Linux", "Azure Static Web App"],
  },
];

const workExperience = [
  {
    ID: 1,
    Position:
      "Career Connections Intern (Jul 21 - Aug 22), Graduate Assistant (Aug - Dec 22)",
    Period: "August 2018 – December 2022",
    Employer: "University of Nebraska-Lincoln Career Services",
    Location: "Lincoln, NE",
    Description: [
      "Brainstormed, developed, and coordinated the Micro-Internship and Mentoring Program (MIMP) alongside the Assistant Director and another Graduate Assistant by setting goals and deadlines for the program to be launched on time",
      "Communicated via email and delivered presentations to employers, faculty, staff, and students to educate them on MIMP and how they could get involved",
      "Conducted interviews with students to understand their career aspirations and goals to direct and/or find the best professional opportunities for them while taking notes and entering data on spreadsheets for later analysis",
      "Learned HTML and user experience trends to design and shape web resources that caters to the Gen Z student population",
      "Developed web resources using HTML, CSS and/or a block editor (through the admin platform provided by uConnect) focusing on enhancing user experience through intuitive design and seamless navigation",
      "Managed digital resources by checking for broken links and maintaining information accuracy to ensure seamless user experience",
      "Communicated and collaborated with Career Services staff to fulfill requests for digital resources by exchanging feedback on elements, content, and layout options to ensure desired outcomes were achieved by predetermined deadlines",
      "Built digital resources for the Fall Career Meet-Up and Fall Career Fairs that connected students to opportunities within their unique career interest",
      "Analyzed and synthesized six unique post-career fair evaluation surveys to determine trends and insight from both students and employers to help improve future fair experience",
    ],
  },
];

const generalSkills = [
  {
    ID: 1,
    Type: "Computer",
    Skill: [
      "Microsoft Office (Word, Power Point, Excel, Outlook)",
      "Google Applications (Docs, Sheets, Slides, Forms)",
    ],
  },
  {
    ID: 2,
    Type: "Languages",
    Skill: ["Native Portuguese", "Fluent English", "Intermediate Spanish"],
  },
];

export { certifications, techSkills, workExperience, generalSkills };
