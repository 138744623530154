import React from "react";

function ProgressBar(props) {
  return (
    <div className="progress-bar box-shadow">
      <p>Completed Classes</p>
      <p>{props.progress}/26</p>
    </div>
  );
}

export default ProgressBar;
