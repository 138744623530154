import React from "react";
import {
  homePortfolio,
  aPenny1,
  aPenny2,
  aPenny3,
  aPenny4,
  aPenny5,
  restaurant1,
  restaurant2,
  restaurant3,
  reviews1,
  reviews2,
  reviews3,
  reviews4,
  reviewsDemo,
  TBR1,
  TBR2,
  TBR3,
  TBR4,
  TBR5,
  TTT1,
  TTT2,
} from "../../content/assets/images-export";

function SingleImg(props) {
  switch (props.imgCode) {
    case "POR":
      return <img className="box-shadow" src={homePortfolio} alt={props.alt} />;
    case "REV":
      return <img className="box-shadow" src={reviews1} alt={props.alt} />;
    case "TBR":
      return <img className="box-shadow" src={TBR1} alt={props.alt} />;
    case "BLG":
      return <img className="box-shadow" src={aPenny1} alt={props.alt} />;
    case "RES":
      return <img className="box-shadow" src={restaurant1} alt={props.alt} />;
    case "TTT":
      return <img className="box-shadow" src={TTT1} alt={props.alt} />;
  }
}

function MultipleImgs(props) {
  function handleVideo(e) {
    const video = e.target;
    video.paused ? video.play() : video.pause();
    arguments[0].preventDefault();
  }

  switch (props.imgCode) {
    case "REV":
      return (
        <div className="imgs-container">
          <a
            href="https://drive.google.com/file/d/17CCs4JUFedCkQKC0G5U3njHCrPZ8vSOc/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={reviews1} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/10DRzV2GIU6eBh3XMtQF6Mf_cHUlB8M60/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={reviews2} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1yKysVCzdV2yrywMoh8K8XjH7j28cGMx5/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={reviews3} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1MQXH5_a8JT1r5xCK52kNdCTMHJtlJwz4/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={reviews4} alt={props.alt} className="box-shadow" />
          </a>
          <video
            src={reviewsDemo}
            controls
            controlsList="novolume"
            muted
            className="box-shadow"
            onClick={handleVideo}
          />
        </div>
      );
    case "TBR":
      return (
        <div className="imgs-container">
          <a
            href="https://drive.google.com/file/d/1UgwfWr6w01OsuUmGHnD6M6nTpXJmxwvf/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TBR1} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1tvDvRMGC92U4B9jNxcA5OQelsGRR5U99/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TBR2} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1XgvfndSwM5VhxgQGAJo4iC7Qfapa5CJQ/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TBR3} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1n9u1EiTRm6s9NJ1YfhRP8asVu8QFvedW/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TBR4} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1zgNDGV6viq4vh3apbF81h7X9jHEot4KP/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TBR5} alt={props.alt} className="box-shadow" />
          </a>
        </div>
      );
    case "BLG":
      return (
        <div className="imgs-container">
          <a
            href="https://drive.google.com/file/d/1ZzKGfh3ttSuZ9YJD3M1kMRGLVzXsDc5l/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={aPenny1} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1bWAe-GLYusNwMle3lu1PIGiS3BU4qfiA/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={aPenny2} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1tHyPKwHopoxRVkkcfEfUqLa5VuLlUrbs/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={aPenny3} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1Y2PJSnLUSM-te18ZxBOqClzFh3lkiJ3F/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={aPenny4} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1g94VrUTwzsTpARAjTvnttpbn8x4BsHMf/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={aPenny5} alt={props.alt} className="box-shadow" />
          </a>
        </div>
      );
    case "RES":
      return (
        <div className="imgs-container">
          <a
            href="https://drive.google.com/file/d/1iwyl2lo_s-aa3djF1VK_tJ02kouA_9pz/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={restaurant1} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/17wk6v4hIXC-bjioqlC7rMJHUauprjmLK/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={restaurant2} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1BruGld6iG3Gezqr7PLTdUe50cVG9-nV-/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={restaurant3} alt={props.alt} className="box-shadow" />
          </a>
        </div>
      );
    case "TTT":
      return (
        <div className="imgs-container">
          <a
            href="https://drive.google.com/file/d/1SMETicmO9oTu5YVeSizDnpPmBLEhHk5q/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TTT1} alt={props.alt} className="box-shadow" />
          </a>
          <a
            href="https://drive.google.com/file/d/1H8fxI2TBCLRmnb5Yv7LWJwaYoZYgI9Ln/view?usp=drive_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TTT2} alt={props.alt} className="box-shadow" />
          </a>
        </div>
      );
  }
}

export { SingleImg, MultipleImgs };
