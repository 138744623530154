const first = "My career journey has been anything but linear.";
const paragraph1 =
  "By chance, I fell in love with coding  when I was about to graduate with a B.A. in Psychology. " +
  "Since I was an international student at the time, I had limitations beyond my control that kept me from pursuing a different major and/or career. " +
  "I started a master’s in leadership education to potentially start a career in Education Technology; however, after a semester, I decided that was not the right path for me. ";
const paragraph2 =
  "After working on a bootcamp and realizing I could benefit from a degree in technology, I had the privilege of choosing to become a student again and enrolled full-time in a Software Engineering program at Western Governors University. " +
  "I am excited for future opportunities and to finally start  a career that feels right for me. ";
const last =
  "This portfolio features some of my favorite projects, I hope you enjoy them as much as I do. ";

export { first, paragraph1, paragraph2, last };
