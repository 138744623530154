import React from "react";
import ClassCard from "../../ClassCard";
import classes from "../../content/ClassInfo";

function Classes() {
  let classElements = classes.map(function (item) {
    return (
      <ClassCard
        key={item.ID}
        typeCard="resume-section"
        title={item.Name}
        compUnits={item.CompUnits}
        // description={item.Description}
        curEnrrol={item.CurrentlyEnrrolled}
      />
    );
  });

  return (
    <section>
      <h2 className="line">Courses</h2>
      <div className="classes-container">{classElements}</div>
    </section>
  );
}

export default Classes;
