import React from "react";
import "./styles/home-main.css";
import Icon from "../Icon.jsx";
import { first, paragraph1, paragraph2, last } from "../content/Bio.js";
import Education from "./components/Education.jsx";

function Home() {
  return (
    <main className="home">
      <section className="container-home">
        <div className="home-left">
          <h1>Hello there! Welcome to my portfolio!</h1>
          <h2>
            My name is <span>Luiza Benvenuti Hernandez</span> and I'm an
            aspiring Software Engineer
          </h2>
          <p>{first}</p>
          <p>{paragraph1}</p>
          <p>{paragraph2}</p>
          <p>{last}</p>
        </div>
        <div className="home-right">
          <Icon
            style={{ width: "20rem", height: "20rem", borderRadius: "50%" }}
          />
          <Education />
        </div>
      </section>
    </main>
  );
}

export default Home;
