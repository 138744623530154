const featuredProjects = [
  {
    ID: 5,
    projectCode: "REV",
    Alt: "review website project",
    Title: "Reviews Website",
    Objective: "Develop a web application for reviews utilizing a SQL Database",
    Description:
      "  Before starting on the design and layout of the website, several logistical considerations were necessary." +
      "I had to determine the type(s) of reviews to display, the best way to utilize a SQL database to store the data, and which public APIs, if any, to integrate.\n" +
      "  Having used Open Library API in a previous project, I was already familiar with handling the data coming from their requests. " +
      "Additionally, after further research, I discovered The Movie Database (TMDB) API, which, among other things, returns posters for movies and TV shows. " +
      "This led me to build an entertainment reviews page that requests posters and covers from these APIs and queries the reviews and information from the SQL Database.\n" +
      "  My SQL database is organized into two tables. " +
      "The first table stores the ID, name, type, and image information. " +
      "These direct image links or endpoints are requested when a review is created, eliminating the need to send full API requests each time the website is rendered. " +
      "The second table contains the ID, rating, date finished, and reviews. " +
      "This separation optimizes table size and allows the app to filter by the exact type of reviews before joining with the table that holds the detailed reviews and additional information.\n" +
      "  The website allows users to view all entertainment reviews or select specific categories. " +
      "Users can choose categories from the home page or use the dropdown menu to switch categories while browsing.",
    Languages: ["JavaScript", "SQL", "EJS", "Node.js", "Express.js", "REST API", "CSS"],
  },
  {
    ID: 4,
    projectCode: "TBR",
    Alt: "randomizer to-be-read list project",
    Title: "Next-Read Randomizer",
    Objective:
      "Select and develop a web application using a public API and local server",
    Description:
      "  While considering a public API for this project, I had been looking for a way to randomly choose a book to read from my various to-be-read (TBR) lists—both digital and physical. " +
      "The Open Library API was an ideal choice, as it allowed me to create and manage my lists on their website and access them through the API. " +
      "The next step was to set up my TBRs and add books to them so I could retrieve random entries through API requests.\n" +
      "  The locally served website uses a GET request to render the home page, which features four buttons. " +
      "These buttons let users choose whether the random book will be selected from any of my list options or specifically from my physical library, Kindle library, or Apple library. " +
      "When a button is clicked, a POST request with a unique endpoint is sent to the Open Library API to fetch the selected TBR list. " +
      "A ‘Math.random’ function is then used to randomly select an index from the array of book results. " +
      "Finally, the details of the randomly selected book are returned and displayed on the home page.",
    Languages: ["JavaScript", "EJS", "Node.js", "Express.js", "REST API", "JSON", "CSS"],
  },
  {
    ID: 3,
    projectCode: "BLG",
    Alt: "blog, enter your thoughts web app project",
    Title: "Blog Web App",
    Objective:
      "Create a locally served web application that allow users to view, create, edit, and delete posts",
    Description:
      "  This project includes EJS, CSS, and JavaScript files. " +
      'Built to be a locally served app, the data submitted as "thoughts" is stored in a JavaScript object only while the server is running. ' +
      "No permanent changes to the original object are made, therefore restarting the server will revert it to its initial state. " +
      "The app's logic functions as a basic local API, allowing the user to create, edit, and delete data entries through corresponding buttons on the form.\n" +
      "  To create a post, the user does not need to manually enter an ID, as it is automatically generated. " +
      "However, the ID must be specified to execute edit or delete actions, so the local API can locate the correct item to modify or remove.",
    Languages: ["JavaScript", "EJS", "Node.js", "Express.js", "REST API", "CSS"],
  },
  {
    ID: 2,
    projectCode: "RES",
    Alt: "restaurant page project",
    Title: "Restaurant Page",
    Objective:
      "Build a restaurant website with multiple pages, using mainly JavaScript and manipulation of the DOM",
    Description:
      "  Although this project required good understanding of HTML elements and tags, it only has a basic boilerplate HTML document as a foundation for rendering and manipulating the DOM using JavaScript. " +
      "Additionally, I have used the Webpack bundler for asset and output management.\n" +
      "  The restaurant website contains three main pages – home, menu, and contact us – each dynamically populated when their respective buttons are selected by the user. " +
      "This approach was intended to enhance the website’s speed and lower the amount of information being rendered right away. " +
      "The same applies to the information under the menu page, where initially there are three menu/food options on a dropdown selector. " +
      "The pictures and descriptions for each option are only rendered when their respective menu is chosen.\n" +
      "  The form under the contact us tab validates user input, triggering an alert if the submit button is pressed before the entire form is completed. " +
      "Since I did not intend to retrieve or use the information submitted through the form, I added an extra alert to inform users that the information will not be stored after submission.\n" +
      "  The name and content for this restaurant page was influenced by my husband’s love for capybaras.",
    Languages: ["JavaScript", "CSS"],
    URL: "https://l-benvenuti.github.io/project-restaurant-page/",
  },
  {
    ID: 1,
    projectCode: "TTT",
    Alt: "tic-tac-toe project",
    Title: "Tic-Tac-Toe Game",
    Objective: "Develop a simple game of tic-tac-toe",
    Description:
      "  This was one of my earlier projects with JavaScript. " +
      "It keeps track of all potential winning sequences in arrays so they can be compared to the squares selected during the game. " +
      "If one of the winning sequences is achieved, it updates the inner text of the paragraph below the game board to announce the winner. " +
      "The restart button was a late addition for easy page refresh.",
    Languages: ["HTML", "CSS", "JavaScript"],
    URL: "https://l-benvenuti.github.io/Project_Tic-Tac-Toe/",
  },
];

const portfolio = {
  ID: "P1",
  projectCode: "POR",
  noButton: true,
  Alt: "screenshot of portfolio's home page",
  Title: "My Portfolio",
  Description:
    "This portfolio was built using React, JavaScript, and CSS, and it has been deployed on Azure Static Web App through GitHub. Custom domain was obtained from Cloudflare and set up on Azure.",
  Languages: ["React", "JavaScript", "CSS", "Azure Static Web App"],
};


export { featuredProjects, portfolio };

// Project Example
// {
//   ID: ,
//   projectCode: "",
//   Alt: "",
//   Title: "",
//   Objective: "",
//   Description: "",
//   Languages: [],
// }