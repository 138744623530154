import React from "react";
import { ProfilePic } from "./content/assets/images-export";

const customIconStyle = {
  width: "10rem",
  borderRadius: "50%",
};

function Icon(props) {
  return (
    <img
      src={ProfilePic}
      alt="hey, that's me"
      style={props.style == null ? customIconStyle : props.style}
    />
  );
}

export default Icon;
